import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';

function LandingPage() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Coming Soon</h1>
        <p>Our website is under construction. Check back soon!</p>
        <Link className="App-link" to="/contact">
          Sign up to receive a notification
        </Link>
      </header>
    </div>
  );
}

function ContactPage() {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement email submission logic here
    alert('Thank you for signing up!');
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Subscribe to Our Newsletter</h1>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            name="email"
            placeholder="Enter your email"
            required
          />
          <button type="submit">Subscribe</button>
        </form>
        <Link className="App-link" to="/">
          Back to Home
        </Link>
      </header>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
    </Router>
  );
}

export default App;
